import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import { Box, Heading, Text } from 'rebass'
import Hero from '../components/base/Hero'

export default ({ data }) => (
  <>
    <HelmetDatoCms seo={data.datoCmsBlog.seoMetaTags} />
    <Hero>
      <Heading as="h1" variant="hero.heading">Blog</Heading>
      <Heading as="h2" variant="hero.subheading">{data.datoCmsBlog.title}</Heading>
    </Hero>
    <Box mb={6}>
      <Box variant="fixed" my={5}>
          <Text>Published {data.datoCmsBlog.meta.publishedAt}.</Text>
          <Text
              dangerouslySetInnerHTML={{
                  __html: data.datoCmsBlog.contentNode.childMarkdownRemark.html,
              }}
          />
      </Box>
    </Box>
  </>
)

export const query = graphql`
  query BlogQuery($slug: String!) {
    datoCmsBlog(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      meta {
        publishedAt(fromNow: true)
      }
    }
  }
`
